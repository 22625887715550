<template>
  <div class="identity">
    <BaseHeaderBar
      title="Informasi Identitas"
      :showCustomerService="false"
      :showBackButton="true"
    />
    <div class="content">
      <div class="uploade">
        <van-uploader
          class="uploader"
          preview-size="0"
          :preview-image="false"
          capture="camera"
          :after-read="afterKtpRead"
        >
          <van-image v-if="ktpImg" width="300" height="230" :src="ktpImg" />
          <img
            v-else
            width="300"
            height="230"
            src="@/assets/img/identity.png"
          />
          <img class="identity-camera" src="@/assets/img/identity-camera.png" />
        </van-uploader>
        <div class="label">
          <p>KTP</p>
        </div>

        <van-overlay
          :show="ktpLoadingInfo.show"
          @click="ktpLoadingInfo.show = false"
          :custom-style="{ position: 'absolute' }"
        >
          <van-loading color="#2952E5" vertical>{{
            ktpLoadingInfo.text
          }}</van-loading>
        </van-overlay>
      </div>
      <div class="uploade">
        <div
          class="uploader"
          @click="afterUserRead"
          :preview-image="false"
          capture="camera"
        >
          <van-image v-if="userImg" width="300" height="230" :src="userImg" />
          <img
            v-else
            width="300"
            height="230"
            src="@/assets/img/recognition.png"
          />

          <img class="identity-camera" src="@/assets/img/identity-camera.png" />
        </div>
        <div class="label">
          <p>Fitur Pengenalan Wajah</p>
        </div>
        <van-overlay
          :show="userLoadingInfo.show"
          @click="ktpLoadingInfo.show = false"
          :custom-style="{ position: 'absolute' }"
        >
          <van-loading color="#2952E5" vertical>{{
            userLoadingInfo.text
          }}</van-loading>
        </van-overlay>
      </div>
      <button type="button" class="confirm-btn" @click="onSubmit">Kirim</button>
    </div>
    <van-overlay :show="onSubmitShow">
      <van-loading color="#2952E5" vertical></van-loading>
    </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue'
import { Uploader, Toast, Loading, Overlay, Image as VanImage } from 'vant'
import axios from 'axios'
import Compressor from 'compressorjs'
import request from '@/utils/request'

Vue.use(Uploader)
Vue.use(Toast)
Vue.use(Loading)
Vue.use(Overlay)
Vue.use(VanImage)

function randomString (len = 32) {
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

export default {
  name: 'Identity',
  data () {
    return {
      imgSrc: '',
      ktpImg: '',
      ktpLoadingInfo: {
        show: false,
        text: 'Proses Unggah'
      },
      userImg: '',
      userLoadingInfo: {
        show: false,
        text: 'Proses Unggah'
      },
      onSubmitShow: false
    }
  },
  created () {
    this.getIdentify()
    this.uploadPromise = this.getUploadParams()
  },
  methods: {
    getIdentify () {
      request.post('iddrentityInfoPage')
        .then(res => {
          this.userImg = res.usdrerLiving_imgurl
        })
    },
    takePhoto () {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const video = document.getElementById('video')
      const mediaConstraints = {
        audio: false,
        video: {
          width: { ideal: 375 },
          height: { ideal: 250 },
          frameRate: {
            ideal: 30,
            max: 10
          },
          facingMode: 'user'
        }
      }
      const errBack = function (error) {
        console.log('Video capture error: ', error.code)
      }
      setInterval(() => {
        context.drawImage(video, 0, 0, 375, 250)
        this.imgSrc = canvas.toDataURL('image/png')
      }, 3000)

      const getUserMedia = this.isSupportUserMedia()
      if (getUserMedia) {
        getUserMedia(mediaConstraints, function (stream) {
          video.srcObject = stream
          video.play()
        }, errBack)
      }
    },
    isSupportUserMedia () {
      return navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia
    },
    getUploadParams () {
      return request.post('updrloadImgToken')
    },
    uploadImg (file) {
      return new Promise((resolve, reject) => {
        this.uploadPromise.then(res => {
          const key = 'web/' + Date.now() + randomString(10) + '-' + file.name
          const formData = new FormData()
          formData.append('OSSAccessKeyId', 'LTAI5tD9wrLfvn8sEpRLfpbw')
          formData.append('key', key)
          formData.append('policy', res.podrlicy)
          formData.append('signature', res.sidrgnature)
          formData.append('file', file)
          formData.append('success_action_status', 200)
          axios.post('https://inphotos.oss-ap-southeast-1.aliyuncs.com', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => resolve(`https://inphotos.oss-ap-southeast-1.aliyuncs.com/${key}`))
            .catch(error => {
              Toast('Unggal Gagal')
              reject(error)
            })
        }).catch(error => {
          Toast('Unggal Gagal')
          reject(error)
        })
      })
    },
    afterKtpRead (file) {
      this.ktpLoadingInfo.show = true
      this.compressImage(file.file, result => {
        this.uploadImg(result).then(res => {
          console.log(res)
          this.ktpImg = res
        }).finally(() => {
          this.ktpLoadingInfo.show = false
        })
      })
    },
    compressImage (file, success) {
      let quality = 1
      const size = file.size
      const baseQuality = 1024 * 1024
      if (size < baseQuality) { // 小于1M
        quality = 0.8
      } else if (size < 5 * baseQuality) { // 小于5M
        quality = 0.4
      } else if (size < 10 * baseQuality) { // 小于10M
        quality = 0.1
      } else { // 大于10M
        quality = 0
      }
      console.log('size', size, quality)
      new Compressor(file, {
        quality,
        success
      })
    },
    deleteKtp () {
      this.ktpImg = ''
    },
    deleteUser () {
      this.userImg = ''
    },
    afterUserRead () {
      if (!this.ktpImg) {
        Toast('Silakan foto KTP')
        return false
      }
      this.$router.push({
        path: '/userRead'
      })
    },
    validate () {
      if (!this.ktpImg) {
        Toast('Silakan foto KTP')
        return false
      }
      if (!this.userImg) {
        Toast('Silakan foto selfie')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      this.onSubmitShow = true
      const postData = {
        phdrotoList: JSON.stringify([{
          phdrotoUrl: this.ktpImg,
          phdrotoType: '1',
          uploadType: navigator.platform,
          imgSource: this.KtpImgSource,
          uuid: sessionStorage.getItem('ixabisoelinye')
        }, {
          phdrotoUrl: this.userImg,
          phdrotoType: '2',
          uploadType: navigator.platform,
          imgSource: this.userImgSource,
          uuid: sessionStorage.getItem('ixabisoelinye')
        }])
      }
      request.post('iddrentityInfosubmit', postData)
        .then(() => {
          Toast('Pembaruan infomasi identitas berhasil')
          this.$router.back()
        })
        .catch(res => {
          if (res && res.code === 502) {
            this.deleteKtp()
          }
        })
        .finally(() => {
          this.flag = false
          this.onSubmitShow = false
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    const { userReadUrl, isFromCamera } = to.query
    if (userReadUrl) {
      next(vm => {
        // 在这里，`vm` 是组件的实例，你可 以访问它的数据和方法
        vm.userImg = userReadUrl
        vm.userImgSource = isFromCamera
      })
      return
    }
    next()
  }
}
</script>

<style scoped lang="less">
.uploade {
  padding-top: 40px;
  width: 600px;
  margin: 0 auto;
  margin-bottom: 40px;
  .uploader {
    position: relative;
    padding: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.identity {
  display: flex;
  flex-direction: column;
}

.content {
  div {
    .label {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: 34px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 10px;
    }

    .identity-camera {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 87px;
      height: 64px;
      z-index: 999;
      pointer-events: none;
    }

    /deep/ .van-loading {
      position: absolute;
    }
  }

  .ktp-img,
  .user-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.6);

  img {
    width: 40px;
    height: 40px;
  }
}

.confirm-btn {
  .submit-btn;
  width: 670px;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>
